<template>
  <div>
    <div>
      <video :key="cheerUrl" width="2" height="2" autoplay loop>
        <source :src="cheerUrl" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>

    <div class="envelope">
      <div class="envelope__tab"></div>
      <div class="envelope__letter">
        <p>{{ cheerTitle }}</p>
      </div>
      <div class="envelope--base"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cheerUrl: "",
      cheerTheme: "",
      cheerTitle: "I Love You. Happy Valentines Day"
    };
  },
  mounted() {
    const db = this.$firebase.firestore();

    (async () => {
      const celebrationCheer = await db
        .collection("cheers")
        .doc("WerBPgtJ7j7CutO0F16M")
        .get();

      const celebrationTheme = await db
        .collection("insta_celebration")
        .doc("1610037545")
        .get();

      this.cheerUrl = celebrationCheer.data().url;
      this.cheerTheme = celebrationTheme.data().theme_url;
      document.body.style.backgroundImage = `url(${
        celebrationTheme.data().theme_url
      })`;
    })();
  }
};
</script>

<style>
html,
body {
  height: 100%;
}
body {
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #999;
}

div,
body {
  margin: 0;
  padding: 0;
  font-family: exo, sans-serif;
}

/* COLORS */
body {
  display: flex;
  justify-content: center;
}
.envelope {
  margin-top: 60vh;
  width: 224px;
  height: 224px;
  overflow: hidden;
  position: relative;
  transition: all ease;
  animation: tremble 1s infinite;
}
.envelope--base {
  top: 100px;
  z-index: 2;
  width: 220px;
  height: 121px;
  position: relative;
  border-radius: 4px;
  border: 2px solid #00000024;
  background-color: #f5f4f4;
}
.envelope__tab {
  left: 28px;
  z-index: 1;
  bottom: 26px;
  width: 212px;
  height: 140px;
  position: absolute;
  transform: rotateZ(45deg);
  border-top-left-radius: 30px;
  background-color: #f5f4f4;
  border: 2px solid #00000024;
}
.envelope__letter {
  left: 29px;
  z-index: 3;
  width: 158px;
  bottom: 17px;
  height: 181px;
  cursor: pointer;
  font-size: 16px;
  font-style: oblique;
  padding: 12px 18px;
  border-radius: 8px;
  position: absolute;
  box-sizing: border-box;
  background-size: cover;
  color: #4c4444;
  border: 1px solid #00000024;
  transition: all 0.6s linear 0.4s;
  background-image: url("https://i.pinimg.com/564x/03/c3/fe/03c3fe8f10c79787d45f0cff0bc7bb52.jpg");
}
.envelope__letter:hover {
  height: 120px;
  color: #4c4444;
}
.envelope__letter:before {
  content: "";
  height: 0;
  width: 6px;
  right: -34px;
  bottom: -17px;
  position: absolute;
  border-top: 56px solid transparent;
  border-left: 107px solid #ffffff;
  border-right: 107px solid #ffffff;
  border-bottom: 65px solid #f5f4f4;
}
.shadow {
  width: 230px;
  height: 30px;
  position: absolute;
  background-color: #75757524;
  border-radius: 50%;
  bottom: 26vh;
  animation: shadow 3s infinite;
}
@keyframes shadow {
  0% {
    width: 230px;
  }
  50% {
    width: 200px;
  }
  100% {
    width: 230px;
  }
}
@keyframes tremble {
  0% {
    transform: rotate(-1deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
