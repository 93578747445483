<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <CelebrationThemes />
  </div>
</template>

<script>
// @ is an alias to /src
import CelebrationThemes from "@/components/CelebrationThemes.vue";

export default {
  name: "About",
  components: {
    CelebrationThemes
  }
};
</script>
