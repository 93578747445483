var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('h1',[_vm._v("Current "+_vm._s(this.$route.params.categoryId)+" Celebration Theme")]),_c('v-app',{attrs:{"id":"inspire"}},[_c('v-container',{staticClass:"grey lighten-5"},[_c('v-row',{attrs:{"no-gutters":""}},[_vm._l((_vm.instaCheerCategories),function(item){return [_c('v-col',{key:item.key},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"344"}},[_c('v-img',{attrs:{"src":item.theme_url,"height":"200px"}}),_c('v-card-title',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","rounded":"","text":""},on:{"click":function($event){return _vm.deleteCategory(item.id)}}},[_vm._v(" Delete ")])],1),_c('v-spacer')],1)],1),(item === 2)?_c('v-responsive',{key:("width-" + item),attrs:{"width":"100%"}}):_vm._e()]})],2)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Add Theme ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(this.$route.params.categoryId)+" Theme")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Theme Name","required":""},model:{value:(_vm.themeName),callback:function ($$v) {_vm.themeName=$$v},expression:"themeName"}}),_c('div',[_c('v-btn',{on:{"click":_vm.click1}},[_vm._v("choose a photo")]),_c('input',{ref:"input1",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.previewImage}})],1),(_vm.imageData != null)?_c('div',[_c('img',{staticClass:"preview",attrs:{"height":"268","width":"356","src":_vm.img1}}),_c('br')]):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.addCategory()}}},[_vm._v(" Save ")])],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Add Cheers ")])]}}]),model:{value:(_vm.dialog1),callback:function ($$v) {_vm.dialog1=$$v},expression:"dialog1"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(this.$route.params.categoryId)+" Theme")])]),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-subheader',{domProps:{"textContent":_vm._s('Select Cheers to add')}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.states,"label":"Select","multiple":"","chips":"","hint":"Select the cheers you want to add","persistent-hint":""},model:{value:(_vm.e7),callback:function ($$v) {_vm.e7=$$v},expression:"e7"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog1 = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.addCheer()}}},[_vm._v(" Save ")])],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" View Attached Cheers ")])]}}]),model:{value:(_vm.dialogc),callback:function ($$v) {_vm.dialogc=$$v},expression:"dialogc"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(this.$route.params.categoryId)+" Theme")])]),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-subheader',{domProps:{"textContent":_vm._s('Select Cheers to Remove them')}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.attachedCheers,"label":"Select","multiple":"","chips":"","hint":"Selected would be removed","persistent-hint":""},model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogc = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.deleteCheer()}}},[_vm._v(" Delete ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }