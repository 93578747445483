<template>
  <div id="app">
    <h1>Current {{ this.$route.params.categoryId }} Celebration Theme</h1>
    <v-app id="inspire">
      <v-container class="grey lighten-5">
        <v-row no-gutters>
          <template v-for="item in instaCheerCategories">
            <v-col :key="item.key">
              <v-card class="mx-auto" max-width="344">
                <v-img :src="item.theme_url" height="200px"></v-img>

                <v-card-title>
                  {{ item.name }}
                </v-card-title>

                <v-card-actions>
                  <v-btn @click="deleteCategory(item.id)" outlined rounded text>
                    Delete
                  </v-btn>
                </v-card-actions>

                <v-spacer></v-spacer>
              </v-card>
            </v-col>
            <v-responsive
              v-if="item === 2"
              :key="`width-${item}`"
              width="100%"
            ></v-responsive>
          </template>
        </v-row>
      </v-container>

      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Add Theme
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline"
                >{{ this.$route.params.categoryId }} Theme</span
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="themeName"
                      label="Theme Name"
                      required
                    ></v-text-field>
                    <div>
                      <v-btn @click="click1">choose a photo</v-btn>
                      <input
                        type="file"
                        ref="input1"
                        style="display: none"
                        @change="previewImage"
                        accept="image/*"
                      />
                    </div>

                    <div v-if="imageData != null">
                      <img
                        class="preview"
                        height="268"
                        width="356"
                        :src="img1"
                      />
                      <br />
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="addCategory()">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row justify="center">
        <v-dialog v-model="dialog1" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Add Cheers
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline"
                >{{ this.$route.params.categoryId }} Theme</span
              >
            </v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-row align="center">
                  <v-col cols="12" sm="6">
                    <v-subheader v-text="'Select Cheers to add'"></v-subheader>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="e7"
                      :items="states"
                      label="Select"
                      multiple
                      chips
                      hint="Select the cheers you want to add"
                      persistent-hint
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog1 = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="addCheer()">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row justify="center">
        <v-dialog v-model="dialogc" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              View Attached Cheers
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline"
                >{{ this.$route.params.categoryId }} Theme</span
              >
            </v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-row align="center">
                  <v-col cols="12" sm="6">
                    <v-subheader
                      v-text="'Select Cheers to Remove them'"
                    ></v-subheader>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="selection"
                      :items="attachedCheers"
                      label="Select"
                      multiple
                      chips
                      hint="Selected would be removed"
                      persistent-hint
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogc = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="deleteCheer()">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-app>
  </div>
</template>

<script>
export default {
  data() {
    return {
      instaCheerCategories: [],
      dialogm1: "",
      dialogc: false,
      dialog: false,
      dialog1: false,
      caption: "",
      img1: "",
      imageData: null,
      firebaseUrl: "",
      themeName: "",
      e7: [],
      states: [],
      cheers: [],
      attachedCheers: [],
      selection: []
    };
  },
  methods: {
    async addCategory() {
      let obj = this;
      let categoryId = this.$route.params.categoryId;
      toString;
      let id = Math.round(+new Date() / 1000).toString();
      console.log(typeof id, typeof categoryId);
      const db = this.$firebase.firestore();
      console.log(this.themeName);
      console.log(this.firebaseUrl);
      if (this.firebaseUrl && this.themeName) {
        const data = {
          categoryId: categoryId,
          fileType: "image",
          id: id,
          name: this.themeName,
          theme_url: this.firebaseUrl
        };
        console.log(data);

        // Add a new document in collection "cities" with ID 'LA'
        db.collection("insta_celebration")
          .doc(id)
          .set(data)
          .then(function() {
            obj.dialog = false;
            obj.dialog1 = false;
            console.log("done uploaded");
          })
          .catch(function(err) {
            console.log(err);
          });
      } else {
        console.log("Name and Image are needed");
      }
    },
    async addCheer() {
      let obj = this;
      let categoryId = this.$route.params.categoryId;
      console.log(categoryId);
      const db = this.$firebase.firestore();
      const citiesRef = db.collection("cheers");

      let arr = this.e7;

      arr.forEach(async element => {
        element = element.toString();

        const snapshot = await citiesRef.where("name", "==", element).get();
        if (snapshot.empty) {
          alert("No matching documents.");
          return;
        }

        snapshot.forEach(async doc => {
          console.log(doc.id, "=>", doc.data());
          const cityRef = citiesRef.doc(doc.id);

          cityRef
            .set(
              {
                celebrationId: categoryId
              },
              { merge: true }
            )
            .then(function() {
              obj.dialog1 = false;
              obj.dialog = false;
            });
        });
      });
    },
    click1() {
      this.$refs.input1.click();
    },
    previewImage(event) {
      this.uploadValue = 0;
      this.img1 = null;
      this.imageData = event.target.files[0];
      this.onUpload();
    },
    onUpload() {
      this.img1 = null;
      const storageRef = this.$firebase
        .storage()
        .ref(`${this.imageData.name}`)
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.img1 = url;
            this.firebaseUrl = url;
            console.log(this.img1);
          });
        }
      );
    },
    async deleteCategory(id) {
      const db = this.$firebase.firestore();
      console.log(id);
      db.collection("insta_celebration")
        .doc(id)
        .delete()
        .then((this.dialog = false));
    },
    async deleteCheer() {
      let obj = this;
      const db = this.$firebase.firestore();
      const citiesRef = db.collection("cheers");

      let arr = this.selection;

      arr.forEach(async element => {
        element = element.toString();
        console.log(element);

        const snapshot = await citiesRef.where("name", "==", element).get();
        if (snapshot.empty) {
          alert("No matching documents.");
          return;
        }

        snapshot.forEach(async doc => {
          console.log(doc.id, "=>", doc.data());
          const cityRef = citiesRef.doc(doc.id);

          cityRef
            .set(
              {
                celebrationId: ""
              },
              { merge: true }
            )
            .then(function() {
              alert("deleted sucessfully");
              obj.dialogc = false;
              obj.dialog = false;
            })
            .catch(error => {
              alert(error);
            });
        });
      });
    }
  },
  async mounted() {
    let categoryId = this.$route.params.categoryId;

    const db = this.$firebase.firestore();
    // const citiesRef = db.collection("insta_celebration");

    const doc = db
      .collection("insta_celebration")
      .where("categoryId", "==", categoryId);

    doc.onSnapshot(
      snap => {
        const instaCheerCategories = [];
        snap.forEach(doc => {
          instaCheerCategories.push(doc.data());
        });
        this.instaCheerCategories = instaCheerCategories;
      },
      err => {
        console.log(`Encountered error: ${err}`);
      }
    );

    db.collection("cheers")
      .where("celebrationId", "==", categoryId)
      .onSnapshot(
        snap => {
          const attachedCheers = [];
          snap.forEach(doc => {
            attachedCheers.push(doc.data().name);
          });
          this.attachedCheers = attachedCheers;
        },
        err => {
          console.log(`Encountered error: ${err}`);
        }
      );

    db.collection("cheers")
      .get()
      .then(snap => {
        const cheers = [];
        snap.forEach(doc => {
          cheers.push(doc.data().name);
        });
        this.states = cheers;
      });
  }
};
</script>

<style lang="scss"></style>
