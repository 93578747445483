<template>
  <div id="app">
    <h1>Upload New Background Video</h1>
    <div>
      <h3>current video</h3>
      <video :key="currentVideoUrl" width="320" height="240" controls>
        <source :src="currentVideoUrl" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    <v-app id="inspire">
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Add Video
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <div>
                      <v-btn @click="click1">choose a video</v-btn>
                      <input
                        type="file"
                        ref="input1"
                        style="display: none"
                        @change="previewImage"
                        accept="video/*"
                      />
                    </div>

                    <div v-if="imageData != null">
                      <video
                        :key="firebaseUrl"
                        class="preview"
                        width="320"
                        height="240"
                        controls
                      >
                        <source :src="firebaseUrl" type="video/mp4" />

                        Your browser does not support the video tag.
                      </video>

                      <br />
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="addBackgroundVideo()">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-app>
  </div>
</template>

<script>
export default {
  data() {
    return {
      instaCheerCategories: [],
      dialog: false,
      caption: "",
      img1: "",
      imageData: null,
      firebaseUrl: "",
      currentVideoUrl: ""
    };
  },
  methods: {
    async addBackgroundVideo() {
      const db = this.$firebase.firestore();
      let obj = this;
      let url = this.firebaseUrl;

      if (this.firebaseUrl) {
        // Add a new document in collection "cities" with ID 'LA'
        db.collection("videos")
          .doc("video")
          .set(
            {
              url: url
            },
            { merge: true }
          )
          .then(function() {
            obj.dialog = false;
            console.log("done uploaded");
          })
          .catch(function(err) {
            console.log(err);
          });
      } else {
        console.log("Name and Image are needed");
      }
    },
    click1() {
      this.$refs.input1.click();
    },
    previewImage(event) {
      this.uploadValue = 0;
      this.img1 = null;
      this.imageData = event.target.files[0];
      this.onUpload();
    },
    onUpload() {
      this.img1 = null;
      const storageRef = this.$firebase
        .storage()
        .ref(`${this.imageData.name}`)
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.img1 = url;
            this.firebaseUrl = url;
            console.log(this.img1);
          });
        }
      );
    }
  },
  mounted() {
    const db = this.$firebase.firestore();
    const doc = db.collection("videos");

    doc.onSnapshot(
      snap => {
        let currentVideoUrl = "";
        snap.forEach(doc => {
          currentVideoUrl = doc.data();
        });
        this.currentVideoUrl = currentVideoUrl.url;
      },
      err => {
        console.log(`Encountered error: ${err}`);
      }
    );
  }
};
</script>

<style></style>
